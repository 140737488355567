import * as React from 'react';
import { IProductConfig, ISelectedProductInformation } from 'components/Catalog/Product/Detail';
import { empty } from '../../../../../helpers/empty';
import { CartType } from 'components/Cart/Enum/CartType';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';
import RequiredProduct from 'components/Catalog/Product/RequiredProduct';
import {
    ISliceConfiguration,
    slice3Types,
} from 'components/Catalog/Product/DetailComponent/Slice3/Slice3ConfigReducer';
import { Dispatch, memo, useEffect } from 'react';
import { useMutation } from 'redux-query-react';
import productSlice3Request from 'data/requests/product/slice3/productSlice3Request';

interface IProps {
    isInterest: boolean;
    productId?: string;
    slice3MethodEnabled?: boolean;
    slice3Config?: ISliceConfiguration;
    cartFlowType: string;
    setCartFlowType: (value: string) => void;
    selectedProductInformation?: ISelectedProductInformation;
    productConfig: IProductConfig;
    dispatchSlice: Dispatch<any>;
}

const Slice3Option = (props: IProps) => {
    const {
        isInterest,
        slice3MethodEnabled,
        cartFlowType,
        setCartFlowType,
        selectedProductInformation,
        productConfig,
        productId,
        dispatchSlice,
        slice3Config,
    } = props;

    const [{}, slice3Request] = useMutation((id: string) => productSlice3Request(id));

    useEffect(() => {
        if (productId && !empty(slice3MethodEnabled)) {
            slice3Request(productId).then((response) =>
                dispatchSlice({ type: slice3Types.UPDATE, payload: response.body }),
            );
        }
    }, [productId, slice3MethodEnabled]);

    return (
        <>
            {!empty(slice3MethodEnabled) && slice3Config && !empty(slice3Config.finalPrice) && (
                <li>
                    {!isInterest && (
                        <input
                            checked={cartFlowType === CartType.SLICE3_CART}
                            onChange={() => setCartFlowType(CartType.SLICE3_CART)}
                            type="radio"
                            name="r01"
                            id="r01_3"
                        />
                    )}
                    <div className="product-pricing__option">
                        <div className="columns">
                            <label htmlFor="r01_3" className="column">
                                <span className="label">{slice3Config.labels.methodName}</span>
                                <span className="price">{slice3Config.finalPrice}</span>
                            </label>
                            <div className="column">
                                <p className="size-large">
                                    <RenderHTML html={slice3Config.labels.description} nowrapper={true} />
                                </p>
                                <p>
                                    <>
                                        <RenderHTML html={slice3Config.labels.aboutSlice3} nowrapper={true} />
                                        <br />
                                        <RenderHTML html={slice3Config.labels.termsSlice3} nowrapper={true} />
                                    </>
                                </p>
                            </div>
                        </div>
                    </div>
                </li>
            )}
        </>
    );
};

export default memo(Slice3Option);
