import * as React from 'react';
import { CSSProperties } from 'react';
import { empty } from '../../../../helpers/empty';
import { IPaymentMethodProps } from '../../../../components/checkout/Payment/PaymentBlock';
import { isArray } from 'lodash';
import Toaster from 'vkid-ui/lib/Components/Toaster/index';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';

interface IProps {
    logoUrl?: string;
    method: string;
    label: string;
    onClick: (event) => void;
    style?: CSSProperties;
    disabled?: boolean; // TODO this needs to be implemented
    disableDefaultLogo?: boolean;
    paymentMethodProps: IPaymentMethodProps;
}

const SimplePayment = (props: IProps) => {
    const { method } = props;
    let logoUrl = props.logoUrl;
    let description = '';
    let descriptionMediaUrl = '';
    const excludedForShippingMethods = window?.paymentLogos?.payment[method]?.excludedForShipping;

    if (!props.logoUrl && window.paymentLogos && window.paymentLogos.payment && window.paymentLogos.payment[method]) {
        logoUrl = window.paymentLogos.payment[method].logo;
        description = window.paymentLogos.payment[method].description;
        descriptionMediaUrl = window.paymentLogos.payment[method].descriptionMedia;
    }
    if (!props.disableDefaultLogo && empty(logoUrl)) {
        logoUrl = `${Env.WEB_PATH}/assets/img/banklink/${method}.png`;
    }

    let excludedMessage = '';
    if (excludedForShippingMethods && isArray(excludedForShippingMethods)) {
        const excludedShippingMatch = excludedForShippingMethods.find(
            (excludedForShipping) =>
                excludedForShipping?.shippingMethodCode === props.paymentMethodProps?.shippingMethod?.methodCode,
        );
        excludedMessage = excludedShippingMatch?.message;
    }

    const showMessage = () => {
        Toaster.addToast({
            intent: 'danger',
            text: excludedMessage,
        });
    };

    return (
        <li className="banklinks-item">
            <button onClick={!empty(excludedMessage) ? showMessage : props.onClick} disabled={props.disabled}>
                {logoUrl && (
                    <div
                        className="banklinks-item-image"
                        style={{ ...props.style, backgroundImage: 'url(' + logoUrl + ')' }}
                    />
                )}
                <div className="banklinks-item-label">{props.label}</div>
            </button>
            {description && description.length && (
                <div className="banklinks-item-description">
                    <span>
                        {description}
                        {window.detailedPaymentEnabled && descriptionMediaUrl ? (
                            <RenderHTML nowrapper={true} html={`<br><img src="${descriptionMediaUrl}"/>`} />
                        ) : (
                            ''
                        )}
                    </span>
                </div>
            )}
        </li>
    );
};

export default SimplePayment;
