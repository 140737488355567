import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import RenderGallery from 'vkid-ui/lib/Components/RenderGallery';
import { ISelectedAttributes, ISwatchConfig } from 'components/Catalog/Product/Configurator';
import { IPrice } from 'components/Catalog/Interfaces/IPrice';
import { IImage } from 'components/Catalog/Interfaces/IImage';
import ReactDOMServer from 'react-dom/server';
import Gallery from 'components/Catalog/Product/Gallery';
import { useMutation } from 'redux-query-react';
import { extractErrors } from '../../../helpers/request/extractErrors';
import formProductRequest, { IProductRequest } from 'data/requests/product/data/formProductRequest';
import toCartRequest from 'data/requests/product/toCartRequest';
import quoteIdRequest from 'data/requests/product/quoteIdRequest';
import FinanceCalculator from 'components/Financing/FinanceCalculator';
import { IFinanceResponse } from 'components/Financing/Interfaces/IFinance';
import Availability from 'components/Catalog/Product/Availability';
import { IAvailabilityResponse } from 'components/Catalog/Product/Interfaces/IAvailability';
import { empty } from '../../../helpers/empty';
import CartWrapper from 'components/Catalog/Product/CartWrapper';
import crossSaleRequest from 'data/requests/product/crossSaleRequest';
import useAuth from '../../../hooks/useAuth';
import { IRequiredProduct } from 'components/Cart/Interfaces/ICartProduct';
import { CartType } from 'components/Cart/Enum/CartType';
import ConfiguratorWrapper from 'components/Catalog/Product/ConfiguratorWrapper';
import BuyBackModal from 'components/BuyBack/BuyBackModal';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from '../../../helpers/rootReducer';
import { includes } from 'lodash';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';
import { IBuyBackConfig } from 'components/BuyBack/BuyBack';
import { IEstoSlice3Config } from 'components/Catalog/Product/DetailComponent/EstoSlice3/EstoSlice3Option';

export enum ProductType {
    SIMPLE_PRODUCT_TYPE = 'simple',
}

export interface ISelectedProductInformation {
    isAvailable: boolean;
    productId?: string;
    sku?: string;
    images?: IImage[];
    prices?: IPrice;
    selectedAttributes?: ISelectedAttributes[];
    name?: string;
}
export interface ICartButtonConfig {
    slice3AddToCartLabel?: string;
    addToCartLabel?: string;
    isDisabled?: boolean;
    interestConfig?: IProductInterestConfig;
    additionalHtml?: string;
}

export interface IProductInterestConfig {
    title: string;
    description: string;
    iframe: string;
}

export interface IProductConfig {
    name: string;
    type: string;
    price: IConfigPrice;
    id: string;
    sku: {
        label: string;
        value: string;
    };
    shortDescription?: string;
    description?: string;
    priceFormat: string;
    successFormat: string;
    preTitle?: string;
    itemBrand?: string;
    priceOld?: string;
    addToCartButton: ICartButtonConfig;
    productNuggets?: string;
    notAvailableLabel?: string;
    eolHtml?: string;
    isConfigurableProduct?: string;
    breadcrumbs: {
        [key: string]: IDetailProductBreadcrumb[];
    };
    category?: string;
}

export interface IDetailProductBreadcrumb {
    label: string;
    url: string;
}

export interface IRequiredProductsPrice {
    products?: IRequiredProduct[];
    priceWithRequiredLabel?: string;
    priceWithRequired?: string;
}

export interface IConfigPrice {
    value: number;
    label: string;
    valueLabel: string;
}
interface IProps {
    dom: string;
    config: IDetailViewConfig;
}

export interface ISmartdealB2BConfig {
    enabled: boolean;
    url?: string;
    imageUrl?: string;
    labels: {
        pay: string;
        smartdealBusiness: string;
    };
}

export interface IDetailViewConfig {
    swatchConfig: ISwatchConfig;
    productConfig: IProductConfig;
    labels: {
        lookTechData: string;
    };
    financialConfig: IFinanceResponse;
    availabilityConfig?: {
        availabilityLabel: string;
        items: IAvailabilityResponse[];
    };
    inbankRentalConfig?: {
        monthlyFee: {
            label: string;
            value: string;
        };
        aboutInbankRental: string;
        chooseRenewalPeriodLabel: string;
        inbankRentalTermsUrl: string;
        inbankRentalLabels: string[];
        extraInfo: string;
        useInsuranceLabel: string;
    };
    inbankRentalEnabled: boolean;
    slice3MethodEnabled: boolean;
    smartdealB2BConfig: ISmartdealB2BConfig;
    buyBackConfig: IBuyBackConfig;
}

const mapStateToProps = (state) => {
    return state.entities?.estoSlice3Request as IEstoSlice3Config;
};

const changeProductLabels = (selectedProductInformation: ISelectedProductInformation) => {
    const skuSpanElement: HTMLElement | null = document.getElementById('detail-product-sku-label');
    const nameSpanElement: HTMLElement | null = document.getElementById('detail-product-name-label');

    if (skuSpanElement && selectedProductInformation.sku) {
        skuSpanElement.innerHTML = selectedProductInformation.sku;
    }

    if (nameSpanElement && selectedProductInformation.name) {
        nameSpanElement.innerHTML = selectedProductInformation.name;
    }
};

const Detail = (props: IProps) => {
    const { customer, isCustomerRequestFinished } = useAuth();
    const { dom, config } = props;

    useEffect(() => {
        if (isCustomerRequestFinished) {
            const productPrice = config.productConfig.price ? config.productConfig.price.value : 0;
            window.dispatchEvent(
                new CustomEvent('gtm_product_event', {
                    detail: {
                        event_name: 'view_item',
                        user_id: customer?.id,
                        name: config.productConfig.name,
                        id: config.productConfig.sku.value,
                        fullprice: config.productConfig.priceOld ?? productPrice,
                        discount: config.productConfig.priceOld ? productPrice : 0,
                        brand: config.productConfig.itemBrand,
                        category: config.productConfig?.category,
                    },
                }),
            );
        }
    }, [isCustomerRequestFinished]);

    const getFlowType = (): string => {
        if (props.config.inbankRentalConfig?.monthlyFee) {
            return window.sessionStorage.getItem('cartFlow') ?? CartType.REGULAR_CART;
        }

        return CartType.REGULAR_CART;
    };

    const estoSlice3Config = useSelector(mapStateToProps);

    const [cartFlowType, setCartFlowType] = useState(getFlowType);

    const {
        productConfig,
        availabilityConfig,
        inbankRentalConfig,
        inbankRentalEnabled,
        slice3MethodEnabled,
        labels,
        smartdealB2BConfig,
        buyBackConfig,
    } = config;
    const [selectedProductInformation, setSelectedProductInformation] = useState<ISelectedProductInformation>({
        isAvailable: true,
    });
    const [price, setPrice] = useState<IConfigPrice>(productConfig.price);
    const formatPrice = (price) =>
        productConfig.priceFormat.replace(productConfig.priceFormat.includes(',') ? '0,00' : '0.00', price.toFixed(2));
    let priceOld = productConfig.priceOld ? formatPrice(parseFloat(productConfig.priceOld)) : undefined;
    const [oldPrice, setOldPrice] = useState<string | undefined>(priceOld);
    const [error, setError] = useState<string>();
    const [success, setSuccess] = useState<string>();
    const parser = new DOMParser();
    const doc = parser.parseFromString(dom, 'text/html');
    const [gallery, setGallery] = useState<string | undefined>(
        doc.querySelector('[data-component="gallery"]')?.innerHTML,
    );
    const [{}, quoteRequest] = useMutation(() => quoteIdRequest());
    const [{}, checkCrossSaleRequest] = useMutation((quoteItemId) => crossSaleRequest(quoteItemId));
    const [{}, addToCartRequest] = useMutation((data, quoteIdMask: string, cartFlowTypePassed: string) =>
        toCartRequest(data, quoteIdMask, cartFlowTypePassed),
    );

    const dispatch = useDispatch();
    const { openOverlays } = useSelector((state: IStoreState) => state.overlaysReducer);

    const addToCart = useCallback(
        async (cartFlowTypePassed?: string) => {
            setError(undefined);
            const quoteIdResponse = await quoteRequest();
            if (quoteIdResponse.status !== 200) {
                // Todo maybe a message?
                window.location.reload();
            }
            const { quoteId } = quoteIdResponse.body;

            const productRequestData: IProductRequest = formProductRequest(
                quoteId,
                productConfig.sku.value,
                productConfig.type,
                selectedProductInformation.selectedAttributes,
            );

            const response = await addToCartRequest(
                productRequestData,
                quoteId,
                !empty(cartFlowTypePassed) ? cartFlowTypePassed : cartFlowType,
            );
            if (response.status !== 200) {
                const errors = extractErrors(response);
                if (errors && errors.error) {
                    setError(errors.error);
                }
            } else {
                setSuccess(productConfig.successFormat.replace('%1', productConfig.name));
                window.dispatchEvent(
                    new CustomEvent('cart-altered', {
                        detail: {
                            action: 'add-to-cart',
                            userId: customer?.id,
                            itemListName: 'Product detail view',
                            sku: selectedProductInformation?.sku
                                ? selectedProductInformation.sku
                                : productConfig?.sku?.value,
                            name: productConfig?.name,
                            priceValue: productConfig?.price?.value,
                            itemBrand: productConfig?.itemBrand,
                        },
                    }),
                );

                if (!response.body?.item_id) {
                    return;
                }

                const checkCrossSaleResponse = await checkCrossSaleRequest(response.body.item_id);

                if (checkCrossSaleResponse.body?.url) {
                    const cartFlowTypeToUse = !empty(cartFlowTypePassed) ? cartFlowTypePassed : cartFlowType;

                    if (cartFlowTypeToUse === CartType.SLICE3_CART) {
                        window.location.href = `${checkCrossSaleResponse.body.url}?slice3`;
                    } else if (cartFlowTypeToUse === CartType.ESTO_SLICE3_CART) {
                        window.location.href = `${checkCrossSaleResponse.body.url}?esto_slice3`;
                    } else {
                        window.location.href = checkCrossSaleResponse.body.url;
                    }
                }
            }
        },
        [addToCartRequest, quoteRequest, cartFlowType],
    );
    useEffect(() => {
        if (selectedProductInformation) {
            if (price && selectedProductInformation.prices) {
                setPrice({
                    value: selectedProductInformation.prices.finalPrice.amount,
                    label: price.label,
                    valueLabel: formatPrice(selectedProductInformation.prices.finalPrice.amount),
                });
            }

            if (selectedProductInformation.prices?.oldPrice) {
                if (
                    selectedProductInformation.prices?.oldPrice.amount !==
                    selectedProductInformation.prices?.finalPrice.amount
                ) {
                    setOldPrice(formatPrice(selectedProductInformation.prices.oldPrice.amount));
                } else {
                    setOldPrice(undefined);
                }
            }

            if (selectedProductInformation.images) {
                const images = <Gallery productName={productConfig.name} images={selectedProductInformation.images} />;
                setGallery(ReactDOMServer.renderToString(images));
            }

            // add time to render empty ProductTechnicalSpecs component with event listener
            setTimeout(() => {
                window.dispatchEvent(
                    new CustomEvent('product-configuration-change', {
                        detail: {
                            productConfig,
                            selectedProductInformation,
                            swatchConfig: config?.swatchConfig,
                        },
                    }),
                );
            }, 50);

            changeProductLabels(selectedProductInformation);
        }
    }, [selectedProductInformation]);

    const toggleBuyBack = () => {
        if (includes(openOverlays, 'buyBack')) {
            dispatch(closeOverlay({ name: 'all' }));
        } else {
            dispatch(openOverlay({ name: 'buyBack' }));
        }
    };

    return (
        <React.Fragment>
            <div className="layout-product__image">
                <div className="product-image">
                    {gallery && (
                        <React.Fragment>
                            {oldPrice && <div className="product-sale">%</div>}
                            <RenderGallery
                                dom={gallery}
                                config={{
                                    showThumbs: true,
                                    showArrows: true,
                                    showIndicators: false,
                                    hasOverlay: true,
                                }}
                            />
                        </React.Fragment>
                    )}
                </div>
                {productConfig.productNuggets && (
                    <div dangerouslySetInnerHTML={{ __html: productConfig.productNuggets }} />
                )}
                {(productConfig.type === ProductType.SIMPLE_PRODUCT_TYPE || selectedProductInformation?.productId) &&
                    availabilityConfig && (
                        <Availability
                            items={availabilityConfig.items}
                            productId={selectedProductInformation?.productId}
                            availabilityLabel={availabilityConfig.availabilityLabel}
                        />
                    )}
            </div>
            <div className={'layout-product__content'}>
                <div className={'longtext'}>
                    {success && <div className={'cart-callout intent-success'}>{success}</div>}
                    {error && <div className={'cart-callout intent-danger'}>{error}</div>}
                    {productConfig.shortDescription && (
                        <React.Fragment>
                            <p dangerouslySetInnerHTML={{ __html: productConfig.shortDescription }} />
                        </React.Fragment>
                    )}
                </div>
                {productConfig.type !== ProductType.SIMPLE_PRODUCT_TYPE && (
                    <>
                        <p>
                            <a className="button" href="#tech">
                                {labels.lookTechData}
                            </a>
                        </p>
                        <hr />
                    </>
                )}
                {productConfig.eolHtml && (
                    <React.Fragment>
                        <hr />
                        <p dangerouslySetInnerHTML={{ __html: productConfig.eolHtml }} />
                    </React.Fragment>
                )}
                {config.swatchConfig && (
                    <React.Fragment>
                        <div className={'product-configurator'}>
                            <ConfiguratorWrapper
                                config={config.swatchConfig}
                                setSelectedProductInformation={setSelectedProductInformation}
                            />
                        </div>
                    </React.Fragment>
                )}
                {price && (
                    <React.Fragment>
                        {
                            <div className={`product-pricing type02`}>
                                {selectedProductInformation.isAvailable && (
                                    <CartWrapper
                                        cartFlowType={cartFlowType}
                                        setCartFlowType={setCartFlowType}
                                        slice3MethodEnabled={slice3MethodEnabled}
                                        estoSlice3Config={estoSlice3Config}
                                        inbankRentalEnabled={inbankRentalEnabled}
                                        price={price}
                                        oldPrice={oldPrice}
                                        monthlyFee={inbankRentalConfig?.monthlyFee}
                                        inbankRentalLabels={inbankRentalConfig?.inbankRentalLabels}
                                        aboutInbankRental={inbankRentalConfig?.aboutInbankRental}
                                        inbankRentalTermsUrl={inbankRentalConfig?.inbankRentalTermsUrl}
                                        chooseRenewalPeriodLabel={inbankRentalConfig?.chooseRenewalPeriodLabel}
                                        productId={selectedProductInformation?.productId || productConfig.id}
                                        productConfig={productConfig}
                                        addToCartOrigin={addToCart}
                                        selectedProductInformation={selectedProductInformation}
                                        swatchConfig={props.config.swatchConfig}
                                        useInsuranceLabel={inbankRentalConfig?.useInsuranceLabel}
                                        extraInfo={inbankRentalConfig?.extraInfo}
                                        smartdealB2BConfig={smartdealB2BConfig}
                                    />
                                )}
                                {!selectedProductInformation.isAvailable && (
                                    <div className={'label notice'}>{productConfig.notAvailableLabel}</div>
                                )}
                            </div>
                        }
                        {config.financialConfig && (
                            <FinanceCalculator
                                config={config.financialConfig}
                                productId={
                                    !empty(productConfig.isConfigurableProduct)
                                        ? selectedProductInformation.productId
                                        : productConfig.id
                                }
                            />
                        )}
                        {config.buyBackConfig && (
                            <>
                                <div className="product-pricing secondary">
                                    <ul className="product-pricing__prices">
                                        <li className="primary">
                                            <RenderHTML html={config.buyBackConfig.labels.title} className="title" />
                                        </li>
                                        <li className="actions">
                                            <button onClick={() => toggleBuyBack()} className="product-pricing__button">
                                                {config.buyBackConfig.labels.buttonLabel}
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <BuyBackModal config={config.buyBackConfig} />
                            </>
                        )}
                    </React.Fragment>
                )}
            </div>
        </React.Fragment>
    );
};

export default Detail;
