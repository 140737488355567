import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import CookieSettings from 'components/CookieSettings';
import { useMutation } from 'redux-query-react';
import saveCookieRequest from 'data/requests/cookie/saveCookieRequest';
import Toaster from 'vkid-ui/lib/Components/Toaster/index';
import Cookies from 'js-cookie';

export interface ICookieProps {
    config: ICookieConfig;
}

export interface ICookieConfig {
    modalTitle: string;
    introText: string;
    settingsLabel: string;
    agreeToAllLabel: string;
    notAgreeLabel: string;
    agreeToSelectedLabel: string;
    cookieSelection: ICookieSelection[];
    showSettingsStraightAway?: boolean;
}

export interface ICookieSelection {
    id: number;
    code: string;
    name: string;
    description: string;
    is_required: boolean;
    isChecked: boolean;
}

declare const gtag: Function;

const hasGdprCookieToken = (): boolean => {
    return !!Cookies.get('gdpr_cookie_token');
};

const Cookie: React.FunctionComponent<ICookieProps> = (props) => {
    const [{}, saveRequest] = useMutation((cookies: ICookieSelection[]) => saveCookieRequest(cookies));

    const [showSettings, setShowSettings] = useState(props.config.showSettingsStraightAway);
    const [defaultConsentSet, setDefaultConsentSet] = useState(false);

    const handleAcceptAll = useCallback(async () => {
        const cookiesToSend = props.config.cookieSelection.map((cookieSelection: ICookieSelection) => {
            cookieSelection.isChecked = true;
            return cookieSelection;
        });
        saveData(cookiesToSend);
    }, []);

    const handleDenyAll = useCallback(async () => {
        const cookiesToSend = props.config.cookieSelection.map((cookieSelection: ICookieSelection) => {
            if (!cookieSelection.is_required) {
                cookieSelection.isChecked = false;
            } else {
                cookieSelection.isChecked = true;
            }
            return cookieSelection;
        });
        saveData(cookiesToSend);
    }, []);

    const saveData = (cookiesToSend: ICookieSelection[]) => {
        saveRequest(cookiesToSend).then((response) => {
            if (response.body) {
                Toaster.addToast({
                    intent: response.body.errors ? 'danger' : 'success',
                    text: response.body.message,
                    asHtml: true,
                });
            }
        });
    };

    useEffect(() => {
        if (showSettings) {
            document.body.closest('html')?.classList.add('overlayopen');
        } else {
            document.body.closest('html')?.classList.remove('overlayopen');
        }
        return function cleanup() {
            document.body.closest('html')?.classList.remove('overlayopen');
        };
    }, [showSettings]);

    if (!hasGdprCookieToken() && !defaultConsentSet) {
        gtag('consent', 'default', {
            security_storage: 'granted',
            analytics_storage: 'denied',
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            ad_storage: 'denied',
            functionality_storage: 'denied',
            personalization_storage: 'denied',
        });
        setDefaultConsentSet(true);
    }

    return (
        <React.Fragment>
            {hasGdprCookieToken() && (
                <button
                    id="ot-sdk-btn"
                    className="ot-sdk-show-settings"
                    aria-label="Cookie settings"
                    onClick={() => setShowSettings(!showSettings)}
                >
                    <span>Cookie settings</span>
                </button>
            )}
            {!hasGdprCookieToken() && (
                <div className="cookiemessage">
                    <div className="cookiemessage__limiter">
                        <div dangerouslySetInnerHTML={{ __html: props.config.introText }} className="content" />
                        <div className="actions">
                            <a href="javascript:void(0)" onClick={() => handleAcceptAll()}>
                                {props.config.agreeToAllLabel}
                            </a>
                            <a href="javascript:void(0)" onClick={() => setShowSettings(!showSettings)}>
                                {props.config.settingsLabel}
                            </a>
                            <a href="javascript:void(0)" onClick={() => handleDenyAll()}>
                                {props.config.notAgreeLabel}
                            </a>
                        </div>
                    </div>
                </div>
            )}
            <CookieSettings
                setShowSettings={setShowSettings}
                showSettings={showSettings}
                config={props.config}
                saveData={saveData}
                handleAcceptAll={handleAcceptAll}
            />
        </React.Fragment>
    );
};

export default Cookie;
