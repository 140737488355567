import * as React from 'react';
import { memo, useEffect, useState } from 'react';
import { ICartButtonConfig, IProductConfig, ISelectedProductInformation } from 'components/Catalog/Product/Detail';
import Overlay from 'vkid-ui/lib/Components/Overlay';
import Block from 'vkid-ui/lib/Components/Block';
import { closeOverlay, openOverlay } from 'data/overlays/overlayHandler';
import { useDispatch, useSelector } from 'react-redux';
import { overlaysSelector } from 'data/overlays/overlaysSelector';
import { includes } from 'lodash';
import RenderHTML from 'vkid-ui/lib/Components/RenderHTML';
import AdditionalProductHtml from 'components/Catalog/Product/DetailComponent/AdditionalProductHtml';
import InbankRentalCartButtonOverlay, {
    IInbankRentalFitModalLabels,
} from 'components/Catalog/Product/InbankRentalCartButtonOverlay';
import { ISwatchConfig } from 'components/Catalog/Product/Configurator';
import { CartType } from 'components/Cart/Enum/CartType';
import { empty } from '../../../helpers/empty';
import { useMutation } from 'redux-query-react';
import cartButtonRequest from 'data/requests/product/cartButton/cartButtonRequest';
import Slice3ValidationOverlay from 'components/Catalog/Product/DetailComponent/Slice3/Slice3ValidationOverlay';
import { ISlice3ModalLabels } from 'components/Catalog/Product/DetailComponent/Slice3/Slice3ConfigReducer';
import { IRenewalPeriod } from 'components/Catalog/Product/DetailComponent/InbankRentalCartOption/InbankRentalOption';

interface IProps {
    buttonConfig: ICartButtonConfig;
    productId?: string;
    isInbankRentalAdd?: boolean;
    isSlice3Add?: boolean;
    isEstoSlice3Add: boolean;
    isEstoSlice3ValidationError: boolean;
    addToCart: () => void;
    addToCartInbankRental?: () => {};
    isInbankRentalOverlay?: boolean;
    timeToRenew?: number;
    renewalPeriods?: IRenewalPeriod[];
    notSamePrioritySkus?: string[];
    isHero?: boolean;
    cartTimeToRenew?: ICartTimeToRenew; // deprecated
    cartHeroProducts?: ICartTimeToRenewValue[];
    productConfig?: IProductConfig;
    inbankRentalFee?: {
        label: string;
        value: string;
    };
    inbankRentalModalIntro?: string;
    inbankRentalModalFitLabels?: IInbankRentalFitModalLabels;
    swatchConfig?: ISwatchConfig;
    selectedProductInformation?: ISelectedProductInformation;
    slice3ModalLabels?: ISlice3ModalLabels;
    isSliceValidationError?: boolean;
    setCartFlowType: (value: string) => void;
    lastRegularOrSlice?: string;
    setLastRegularOrSlice: (value: string) => void;
}

interface ICartTimeToRenew {
    [timeToRenew: number]: ICartTimeToRenewValue[];
}

export interface ICartTimeToRenewValue {
    name: string;
    totalMonthlyPayment: string;
}

const CartButton = (props: IProps) => {
    const {
        productId,
        addToCart,
        isInbankRentalAdd,
        addToCartInbankRental,
        notSamePrioritySkus,
        isSlice3Add,
        isEstoSlice3Add,
        isEstoSlice3ValidationError,
        slice3ModalLabels,
        isSliceValidationError,
        lastRegularOrSlice,
        setLastRegularOrSlice,
    } = props;
    const [buttonConfig, setButtonConf] = useState(props.buttonConfig);

    const [{}, buttonRequest] = useMutation((id: string) => cartButtonRequest(id));

    useEffect(() => {
        if (productId) {
            buttonRequest(productId).then((response) => setButtonConf(response.body));
        }
    }, [productId]);

    const hasNotFittedProducts = () => {
        if (!props.cartHeroProducts || empty(props.cartHeroProducts) || empty(notSamePrioritySkus)) {
            return false;
        }
        Object.keys(props.cartHeroProducts).forEach((key: string) => {
            if (props.cartHeroProducts && props.cartHeroProducts[key]) {
                // @ts-ignore
                Object.keys(props.cartHeroProducts[key]).forEach((subKey: string) => {
                    if (
                        props.cartHeroProducts &&
                        props.cartHeroProducts[key][subKey]['sku'] &&
                        notSamePrioritySkus?.includes(props.cartHeroProducts[key][subKey]['sku'])
                    ) {
                        return true;
                    }
                });
            }
        });
        return true;
    };

    const handleClick = () => {
        if (buttonConfig.interestConfig) {
            dispatch(openOverlay({ name: 'register-interest' }));
        } else {
            dispatch(closeOverlay({ name: 'register-interest' }));
            if (isInbankRentalAdd && addToCartInbankRental) {
                if (
                    !empty(props.isHero) &&
                    props.cartHeroProducts &&
                    props.timeToRenew &&
                    !empty(props.cartHeroProducts)
                ) {
                    dispatch(openOverlay({ name: 'add-smart-deal' }));
                } else if (!empty(props.isHero) && props.cartHeroProducts && hasNotFittedProducts()) {
                    dispatch(openOverlay({ name: 'add-smart-deal' }));
                } else {
                    if (isSliceValidationError && lastRegularOrSlice === CartType.SLICE3_CART) {
                        dispatch(openOverlay({ name: 'add-slice' }));
                    } else {
                        addToCartInbankRental();
                        window.sessionStorage.setItem('cartFlow', CartType.INBANK_RENTAL_CART);
                    }
                }
            } else if (isSlice3Add) {
                window.sessionStorage.setItem('cartFlow', CartType.SLICE3_CART);
                if (isSliceValidationError) {
                    dispatch(openOverlay({ name: 'add-slice' }));
                } else {
                    addToCart();
                }
            } else if (isEstoSlice3Add) {
                window.sessionStorage.setItem('cartFlow', CartType.ESTO_SLICE3_CART);
                if (isEstoSlice3ValidationError) {
                    dispatch(openOverlay({ name: 'add-slice' }));
                } else {
                    addToCart();
                }
            } else {
                window.sessionStorage.setItem('cartFlow', CartType.REGULAR_CART);
                addToCart();
            }

            window.dispatchEvent(new CustomEvent('cart-altered'));
        }
    };

    /** Overlays **/
    const dispatch = useDispatch();
    const { openOverlays } = useSelector(overlaysSelector);

    const buttonElement = (
        <button
            disabled={buttonConfig.isDisabled}
            className={`product-pricing__button ${buttonConfig.isDisabled ? 'disabled' : ''} ${
                buttonConfig.interestConfig ? 'secondary' : ''
            }`}
            onClick={() => handleClick()}
        >
            {(isSlice3Add || isEstoSlice3Add) && buttonConfig.slice3AddToCartLabel
                ? buttonConfig.slice3AddToCartLabel
                : buttonConfig.addToCartLabel}
        </button>
    );

    return (
        <React.Fragment>
            {buttonConfig && (
                <React.Fragment>
                    {!props.isInbankRentalOverlay && <li className="actions">{buttonElement}</li>}
                    {props.isInbankRentalOverlay && buttonElement}
                    {!props.isInbankRentalOverlay && buttonConfig?.additionalHtml && (
                        <li>
                            <AdditionalProductHtml additionalHtml={buttonConfig?.additionalHtml} />
                        </li>
                    )}
                </React.Fragment>
            )}
            {
                <Overlay
                    layout="focusview"
                    title={buttonConfig.interestConfig?.title}
                    isOpen={includes(openOverlays, 'register-interest')}
                    doClose={() => {
                        dispatch(closeOverlay({ name: 'all' }));
                    }}
                    zIndex={16000004} // cookie message + 1
                    size="medium"
                    buttonIcons={[
                        {
                            icon: 'close',
                            onClick: () => {
                                dispatch(closeOverlay({ name: 'all' }));
                            },
                        },
                    ]}
                >
                    <Block>
                        <RenderHTML html={buttonConfig.interestConfig?.description ?? ''} />
                        <div className="iframe">
                            <RenderHTML html={buttonConfig.interestConfig?.iframe ?? ''} />
                        </div>
                    </Block>
                </Overlay>
            }
            {props.cartHeroProducts && !!props.cartHeroProducts?.length && props.inbankRentalFee && (
                <InbankRentalCartButtonOverlay
                    addToCartInbankRental={props.addToCartInbankRental}
                    inbankRentalModalFitLabels={props.inbankRentalModalFitLabels}
                    notFittedInbankRentalProduct={props.cartHeroProducts[0]}
                    inbankRentalFee={props.inbankRentalFee}
                    productConfig={props.productConfig}
                    inbankRentalModalIntro={props.inbankRentalModalIntro}
                    swatchConfig={props.swatchConfig}
                    selectedProductInformation={props.selectedProductInformation}
                />
            )}
            {
                <Slice3ValidationOverlay
                    setCartFlowType={props.setCartFlowType}
                    isInbankRentalAdd={isInbankRentalAdd}
                    addToCart={isInbankRentalAdd ? props.addToCartInbankRental : props.addToCart}
                    slice3ModalLabels={slice3ModalLabels}
                    setLastRegularOrSlice={setLastRegularOrSlice}
                />
            }
        </React.Fragment>
    );
};

export default memo(CartButton);
